import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-referralcode',
  templateUrl: './referralcode.page.html',
  styleUrls: ['./referralcode.page.scss'],
})
export class ReferralcodePage implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }
 dismiss(){
   this.modalController.dismiss();
 }
}
