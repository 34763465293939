import { Component, OnInit } from '@angular/core';
import { NavParams,ModalController } from '@ionic/angular';
import { Router,ActivatedRoute} from '@angular/router';
import { LocadingPage } from '../locading/locading.page'; 


declare var $ : any; 
var result

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.page.html',
  styleUrls: ['./schedule.page.scss'],
})
export class SchedulePage implements OnInit {
  

  constructor(private navParams: NavParams,private modalController: ModalController, private router: Router,private route: ActivatedRoute ) { }

  
  
  depart: string;
  arriver:any;
  deplng:any;
  deplat:any;
  arrlng:any;
  arrlat:any
  typevoiture:number
  km:any;
  prix:any;


  

  ngOnInit() {
    
    console.log(this.navParams.get("depart")+"/"+this.navParams.get("arriver")+"/"+this.navParams.get("deplng")+"/"+this.navParams.get("deplat")
    +"/"+this.navParams.get("arrlng")+"/"+this.navParams.get("arrlat")+"/"+this.navParams.get("typevoiture")+"/"+this.navParams.get("km")+"/"+this.navParams.get("prix"));


  }

 dismiss(){
   this.modalController.dismiss();
 }
	
 loading(){

  var result = $('#mission_heure').val().split('T')[1];
  console.log($('#mission_date').val().split('T')[0]+"/// "+result.split('+')[0]);
  
	this.modalController.dismiss();
    this.modalController.create({
      component:LocadingPage,
      componentProps: { 
        typevoiture: this.navParams.get("typevoiture"),
        depart: this.navParams.get("depart"),
        arriver:this.navParams.get("arriver"),
        deplng:this.navParams.get("deplng"),
        deplat:this.navParams.get("deplat"),
        arrlng:this.navParams.get("arrlng"),
        arrlat:this.navParams.get("arrlat"),
        km:this.navParams.get("km"),
        prix:this.navParams.get("prix"),
        date: $('#mission_date').val().split('T')[0], 
        heure:result.split('+')[0]   
      }
    
    }).then((modalElement)=>
    {
      modalElement.present();   
    }
    )
    
  }


}
 