import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { LocadingPageRoutingModule } from './locading-routing.module';

import { LocadingPage } from './locading.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
	TranslateModule,   
    LocadingPageRoutingModule
  ],
  declarations: [LocadingPage]
})
export class LocadingPageModule {}
