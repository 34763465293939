import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { ReferralcodePageModule } from './referralcode/referralcode.module';
import { SchedulePageModule } from './schedule/schedule.module';
import { LocadingPageModule } from './locading/locading.module';
//import { BookridePageModule } from './bookride/bookride.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_CONFIG, BaseAppConfig } from './app.config';
import { VtPopupPageModule } from './vt-popup/vt-popup.module';
import { BuyappalertPageModule } from './buyappalert/buyappalert.module';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    FormsModule,
    IonicStorageModule.forRoot(),
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule,
    ReferralcodePageModule,
    SchedulePageModule,
    LocadingPageModule,
    VtPopupPageModule,
    BuyappalertPageModule,  
//    BookridePageModule,  
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy } ,
    NativeStorage 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
