import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DataService {
   
  posts:any;

  /*url:string="http://127.0.0.1:8000"; 
  urlServer:string = "http://tlcs.maresa.ma"*/

  url:string="https://taxicielcmt.gebanalysis.com"; 
  urlServer:string = "https://taxicielcmt.gebanalysis.com"

  constructor(private httpClient : HttpClient) {}



  getChauffeurs() {
    //return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
    return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
  }


  getmissionpax(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id);
  }
 
   
  getmissionpaxsuccess(id){
    return this.httpClient.get(this.url+'/api/missions?pax='+id+'&etatMission=4');
  }

  getSingleMission(id) {
    return this.httpClient.get(this.url+'/api/missions/'+id); 
  }



  deletemissionpax(id) {
    return this.httpClient.delete(this.url+'/api/missions/'+id);
  }


  getPaxUtilisateur(id){
    return this.httpClient.get(this.url+'/api/users/'+id);
  }


  getRatingChauffeur(id){
    return  this.httpClient.get(this.url+'/api/rating_drivers?chauffeur='+id);
  }
  


/*
  POST's Requests
*/ 


updatePaxUtilisateur(body:any,id){

  return this.httpClient.put(this.url+'/api/paxes/'+id,body)
}



addClientPost(body:any){
  return this.httpClient.post(this.url+'/api/createcompteclient',body)
}


loginClientPax(body:any){
  return this.httpClient.post(this.url+'/api/client/login',body)
}


addMissionPost(body:any){

  return this.httpClient.post(this.url+'/api/newmission',body)
}

addRatingDriverPost(body:any){

  return this.httpClient.post(this.url+'/api/addratingdriver',body)
}
  


}
